module.exports = {
  account: 'Account',
  project: 'Project',
  application: 'Application',
  environment: 'Environment',
  team: 'Team',
  cost_center: 'Cost Center',
  management: 'Management Group',
  domain: 'Domain',
  provider: 'Provider',
  country: 'Country',
  entity: 'Entity',
  header: {
    title: 'Dimensions',
  },
  export: {
    previewData: 'Preview data',
    exportData: 'Export data',
    exportOptions: 'Export options',
    remainingRows: 'additional item(s)',
  },
  plural: {
    account: 'Accounts',
    project: 'Projects',
    application: 'Applications',
    environment: 'Environments',
    team: 'Teams',
    cost_center: 'Cost centers',
    management: 'Management Groups',
    domain: 'Domains',
    provider: 'Providers',
    country: 'Countries',
    entity: 'Entities',
  },
  entities: {
    budget: {
      link: 'Link a budget',
      linkDescription: 'Choose an option to link your entity:',
      unlink: 'Unlink a budget',
      load: 'Load an existing budget',
      loadDescription: "From your organization's budgets",
      create: 'Create a new budget',
      createDescription: 'Available soon',
      select: 'Select a budget from the following list:',
      loading: 'Loading budgets...',
      noResults: 'No budget found',
      noData: 'No budget',
      title: 'Your budgets',
      linker: 'Confirm the link of the following budgets:',
    },
    dialog: {
      return: 'Return',
      validate: 'Validate',
      next: 'Next',
      addSubDimension: 'Add a subdimension',
      addDimension: 'Add a dimension',
      dimensionType: 'Dimension type',
      subDimensionType: 'Subdimension type',
      dimensionName: 'Name',
      dimensionDescription: 'Description',
      dimensionVisibility: 'Visibility',
      dimensionFilters: 'Filters',
      save: 'Save',
      close: 'Close',
    },
  },
  empty: {
    account: "You don't have any account dimension yet.",
    project: "You don't have any project dimension yet.",
    application: "You don't have any application dimension yet.",
    environment: "You don't have any environment dimension yet.",
    team: "You don't have any team dimension yet.",
    cost_center: "You don't have any cost center dimension yet.",
    management: "You don't have any management group dimension yet.",
  },
  visibility: {
    private: 'Private',
    public: 'Public',
  },
  index: {
    title: 'Welcome to your dimensions!',
    subtitle: 'Click on the list to the left to select a dimension type.',
  },
  alerts: {
    info: 'Soon, you will be able to find here the list of alerts linked to this dimension.',
  },
  budgets: {
    search: 'Search a budget',
  },
  card: {
    noDescription: 'No description',
  },
  delete: {
    title: 'Danger zone',
    subtitle: 'Delete the dimension',
    text: 'This action is irreversible and deletes all dimensions linked to it. Alerts and budgets specific to this dimension will also be deleted. Associated filters, however, will be retained..',
    button: 'Delete',
  },
  explorers: {
    totalCostWithoutTaxes: 'Total cost without taxes',
    trend: 'Trend',
    trendDescription:
      'The trend is calculated based on the last period of the same duration:',
    costPerHour: 'Cost per hour',
    costPerDay: 'Cost per day',
    costPerHourDescription:
      'The cost per hour is calculated based on the number of hours in the selected period.',
    costPerDayDescription:
      'The cost per day is calculated based on the number of days in the selected period.',
    granularity: 'Granularity',
  },
  filters: {
    configure: 'Configure',
  },
  overview: {
    cloudCosts: 'Total cost (Cloud)',
    kubenetesCosts: 'Total cost (Kubernetes)',
    carbonEmissions: 'CO2 emissions (Carbon)',
  },
  drawer: {
    dimensionTitle: 'Menu',
    dimensionsTitle: 'My dimensions',
    settings: 'Settings',
    filters: 'Filters',
    users: 'Users',
    delete: 'Delete',
    overview: 'Overview',
    subdimensions: 'Subdimensions',
    explorers: 'Explorers',
    budgets: 'Budgets',
    alerts: 'Alerts',
  },
  users: {
    info: 'Soon, you will be able to find here the list of users linked to this dimension.',
  },
  charts: {
    unknownRegionCost: 'Unassigned region cost is',
  },
}
